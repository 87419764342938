var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { type: "flex", justify: "center", align: "top" }
        },
        [
          _c("a-col", { attrs: { xs: { span: 24 }, lg: { span: 14 } } }, [
            _c("p", [_vm._v("CONSEIL NATIONAL DES CHIRURGIENS-DENTISTES")]),
            _c("p", [_vm._v("RÉGION - " + _vm._s(_vm.region))]),
            _c("p", { staticClass: "villes" }, [_vm._v(_vm._s(_vm.wilayas))])
          ]),
          _c(
            "a-col",
            {
              staticStyle: { "margin-left": "3%" },
              attrs: { xs: { span: 24, push: 8 }, lg: { span: 8, push: 4 } }
            },
            [
              _c("img", {
                attrs: {
                  id: "logo",
                  src: _vm.profile_img_url ? _vm.profile_img_url : _vm.url
                }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }