<template>
  <span>
      <a   v-if="etat === ETAT_DEMANDE_OUVERTURE_EN_ATTENTE" style="color:dodgerblue">
          En attente
       </a>
      <a v-else-if="etat === ETAT_DEMANDE_OUVERTURE_TRAIETEMENT" style="color:orange">
          Traitement
      </a>
      <a v-else-if="etat === ETAT_DEMANDE_OUVERTURE_ACCEPTER" style="color:#52c41a">
          Acceptée
      </a>
      <a v-else-if="etat === ETAT_DEMANDE_OUVERTURE_REFUSE" style="color:red">
          Refusée
       </a>
  </span>
</template>
<script>
    export default {
        name: "EtatDemandesOuverturesIndicator",
        props: { etat : Number }
    };
</script>
