var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._v("\n    Bienvenue "),
    _c("span", { staticStyle: { color: "black" } }, [
      _vm._v("Dr. " + _vm._s(_vm.name))
    ]),
    _vm._v(" sur\n    votre espace personnel\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }