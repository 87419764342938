<template>
  <a-result :title="message">
    <template #icon>
      <a-icon type="smile" theme="twoTone" />
    </template>
    <template #extra> </template>
  </a-result>
</template>
<script>
export default {
    name : 'EmptyResult',
     props: {
    message: { type: String },
  },
}
</script>