var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isUserRefuser(_vm.etat)
        ? _c("a-alert", {
            staticClass: "font_size",
            staticStyle: { "margin-bottom": "10px" },
            attrs: {
              message:
                "Nous avons le regret de vous informer que votre inscription a été refusée.",
              type: "error",
              "show-icon": ""
            }
          })
        : _vm._e(),
      _vm.isUserAccepter(_vm.etat)
        ? _c("a-alert", {
            staticClass: "font_size",
            staticStyle: { "margin-bottom": "10px" },
            attrs: {
              message:
                "Nous avons le plaisir de vous informer que votre inscription a bien été acceptée, nous vous demandons de bien vouloir nous faire parvenir votre dossier.",
              type: "success",
              "show-icon": ""
            }
          })
        : _vm._e(),
      _vm.isUserNonSoumis(_vm.etat)
        ? _c("a-alert", {
            staticClass: "font_size",
            staticStyle: { "margin-bottom": "10px" },
            attrs: {
              message:
                "Votre dossier n'as pas encore été soumis, veuillez compléter vos informations et scaner vos documents puis confirmer votre inscription.",
              type: "warning",
              "show-icon": ""
            }
          })
        : _vm._e(),
      _vm.isUserEnAttente(_vm.etat)
        ? _c("a-alert", {
            staticClass: "font_size",
            staticStyle: { "margin-bottom": "10px" },
            attrs: {
              message: "Votre inscription est en attente de validation.",
              type: "info",
              "show-icon": ""
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }