var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-result", {
    attrs: { title: _vm.message },
    scopedSlots: _vm._u([
      {
        key: "icon",
        fn: function() {
          return [_c("a-icon", { attrs: { type: "smile", theme: "twoTone" } })]
        },
        proxy: true
      },
      {
        key: "extra",
        fn: function() {
          return undefined
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }