var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.etat === _vm.ETAT_DEMANDE_OUVERTURE_EN_ATTENTE
      ? _c("a", { staticStyle: { color: "dodgerblue" } }, [
          _vm._v("\n        En attente\n     ")
        ])
      : _vm.etat === _vm.ETAT_DEMANDE_OUVERTURE_TRAIETEMENT
      ? _c("a", { staticStyle: { color: "orange" } }, [
          _vm._v("\n        Traitement\n    ")
        ])
      : _vm.etat === _vm.ETAT_DEMANDE_OUVERTURE_ACCEPTER
      ? _c("a", { staticStyle: { color: "#52c41a" } }, [
          _vm._v("\n        Acceptée\n    ")
        ])
      : _vm.etat === _vm.ETAT_DEMANDE_OUVERTURE_REFUSE
      ? _c("a", { staticStyle: { color: "red" } }, [
          _vm._v("\n        Refusée\n     ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }