var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.demandesCount
        ? _c(
            "a-card",
            { attrs: { title: "Demandes ouvertures" } },
            [
              _c("a-badge", {
                attrs: {
                  slot: "extra",
                  count: _vm.demandesCount,
                  "number-style": { backgroundColor: "#009688" }
                },
                slot: "extra"
              }),
              _vm._l(_vm.demandes, function(d) {
                return _c(
                  "p",
                  { key: d.id },
                  [
                    _vm._v(
                      "\n        Votre demande d'ouverture " +
                        _vm._s(d.nom_fr) +
                        " est en \n        "
                    ),
                    _c("etat-demandes-ouvertures-indicator", {
                      attrs: { etat: d.etat }
                    }),
                    _vm._v(".\n    ")
                  ],
                  1
                )
              })
            ],
            2
          )
        : _c("empty-result", {
            attrs: { message: "Vous n'avez aucune demande d'ouverture !" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }