<template>
  <div>
    <a-alert
      v-if="isUserRefuser(etat)"
      message="Nous avons le regret de vous informer que votre inscription a été refusée."
      type="error"
      style="margin-bottom:10px"
      class="font_size"
      show-icon
    />
    <a-alert
      v-if="isUserAccepter(etat)"
      message="Nous avons le plaisir de vous informer que votre inscription a bien été acceptée, nous vous demandons de bien vouloir nous faire parvenir votre dossier."
      type="success"
      style="margin-bottom:10px"
      class="font_size"
      show-icon
    />
    <a-alert
      v-if="isUserNonSoumis(etat)"
      message="Votre dossier n'as pas encore été soumis, veuillez compléter vos informations et scaner vos documents puis confirmer votre inscription."
      type="warning"
      style="margin-bottom:10px"
      class="font_size"
      show-icon
    />
    <a-alert
      v-if="isUserEnAttente(etat)"
      message="Votre inscription est en attente de validation."
      type="info"
      style="margin-bottom:10px"
      class="font_size"
      show-icon
    />
    
  </div>
</template>
<script>
export default {
  name: "UserEtats",
  props: {
    etat: { type: Number },
  },
};
</script>
