<template>
  <span>
    <span v-if="isPayeAjour(balance)">
      Vous êtes à jours jusqu'as l'année {{nextYearExercice}}
    </span>
    <span v-else>
      <span v-if="exercices">
        Vous devez régulariser vos cotisations pour la période
        <b v-bind:style="{ color: RED_COLOR }"> {{ exercices }}. </b>
      </span>
    </span>
  </span>
</template>
<script>
import moment from "moment";
export default {
  name: "ExercicesToPayIndicator",
  props: {
    balance: { type: Number, default: () => 0 },
    exercices: { type: Array },
  },
  computed :{
    nextYearExercice(){
       return  moment().add(1, 'year').year();
    }
  }
};
</script>

