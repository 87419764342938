var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            [
              _c("my-header", {
                attrs: {
                  wilayas: _vm.getOrganisationWilaysNames,
                  profile_img_url: _vm.organisationLogo,
                  region: _vm.getRegion
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticClass: "welcome_msg",
          attrs: { type: "flex", justify: "center" }
        },
        [
          _c(
            "a-col",
            [_c("welcome-message", { attrs: { name: _vm.user.full_name } })],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 15 } },
            [_c("user-etats", { attrs: { etat: _vm.user.etat } })],
            1
          )
        ],
        1
      ),
      _vm.isUserPaiementSettled(_vm.user)
        ? _c(
            "a-row",
            { staticClass: "mt-5", attrs: { justify: "center", type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { xs: 24, sm: 15 } },
                [
                  _c(
                    "a-card",
                    [
                      _c("exercices-to-pay-indicator", {
                        attrs: {
                          exercices: _vm.user.etat_paiement.exercice_a_payer,
                          balance: _vm.user.etat_paiement.balance
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { staticClass: "mt-5", attrs: { xs: 24, sm: 15 } },
            [_c("demandes-attestations")],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mt-5", attrs: { xs: 24, sm: 15 } },
            [_c("demandes-ouvertures")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }