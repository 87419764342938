<template>
  <div>
    <a-card v-if="attestationsCount" title="Demandes attestations">
      <a-badge
        slot="extra"
        :count="attestationsCount"
        :number-style="{ backgroundColor: '#009688' }"
      />

      <p v-for="a in attestations" :key="a.id">
        Votre demande d'attestation {{ a.type_attestation.nom_fr }} est
        <etat-attestation-indicator :etat="a.etat" />.
      </p>
    </a-card>
    <empty-result v-else message="Vous n'avez aucune demande d'attestation !" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import EtatAttestationIndicator from "@/components/common/EtatAttestationIndicator";
import EmptyResult from "./EmptyResult";
export default {
  name: "DemandesAttestationsIndicator",
  created() {
    this.fetchData();
  },
  components: {
    EtatAttestationIndicator,
    EmptyResult,
  },
  computed: {
    ...mapState({
      attestations: (state) =>
        state.adherant_attestations.adherant_attestations,
      user: (state) => state.adherent.adherent,
    }),
    attestationsCount() {
      return this.attestations ? this.attestations.length : 0;
    },
  },
  methods: {
    fetchData() {
      this.$store.cache.dispatch("fetchAdherent").then(() => {
        this.$store.cache.dispatch("fetchAdherentAttestations");
        this.$store.cache.dispatch("fetchAdherentOrganismes");
      });
    },
  },
};
</script>
<style scoped>
p {
  font-weight: 700;
}
</style>
