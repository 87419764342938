<template>
    <div>
        Bienvenue <span style="color: black">Dr. {{ name }}</span> sur
        votre espace personnel
    </div>
</template>
<script>
export default {
    name : 'WelcomeMessage',
    props : {
        name : {type : String}
    }
}
</script>