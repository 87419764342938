<template>
  <div>
    <a-row
      type="flex"
      justify="center"
      align="top"
      style="margin-bottom : 10px"
    >
      <a-col :xs="{ span: 24 }" :lg="{ span: 14 }">
        <p>CONSEIL NATIONAL DES CHIRURGIENS-DENTISTES</p>
        <p>RÉGION - {{region}}</p>
        <p class="villes">{{wilayas}}</p>
      </a-col>
      <a-col
        :xs="{ span: 24, push: 8 }"
        :lg="{ span: 8, push: 4 }"
        style="margin-left : 3%"
      >
        <img id="logo" :src="profile_img_url ? profile_img_url : url" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      url: require("../../assets/new_logo.jpg")
    };
  },
  props: ["profile_img_url", "wilayas","region"]
};
</script>

<style scoped>
p {
  font-size: 1.5em;
  text-align: center;
  color: #009688;
  margin-bottom: 10px;
}
.villes {
  font-size: 1em;
  color: #607d8b;
}

#logo {
  padding-bottom: 10px;
  width: 112px;
  height: auto;
  max-height: 180px;
}
</style>
