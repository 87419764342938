<template>
  <div style="padding: 20px;">
    <a-row type="flex" justify="center">
      <a-col>
        <my-header
          :wilayas="getOrganisationWilaysNames"
          :profile_img_url="organisationLogo"
          :region="getRegion"
        />
      </a-col>
    </a-row>
    <a-row type="flex" class="welcome_msg" justify="center">
      <a-col>
        <welcome-message :name="user.full_name" />
      </a-col>
    </a-row>
    <a-row type="flex" justify="center">
      <a-col :xs="24" :sm="15">
        <user-etats :etat="user.etat" />
      </a-col>
    </a-row>
    <a-row v-if="isUserPaiementSettled(user)" class="mt-5" justify="center" type="flex">
        <a-col :xs="24" :sm="15">
            <a-card>
                <exercices-to-pay-indicator
                    :exercices="user.etat_paiement.exercice_a_payer"
                    :balance="user.etat_paiement.balance"
                />
            </a-card>
        </a-col>
    </a-row>
    <a-row type="flex" justify="center">
      <a-col :xs="24" :sm="15" class="mt-5">
        <demandes-attestations />
      </a-col>
      <a-col :xs="24" :sm="15" class="mt-5">
        <demandes-ouvertures />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import MyHeader from "@/components/common/Header";
import UserEtats from "./UserEtats";
import WelcomeMessage from "./WelcomeMessage";
import DemandesAttestations from "./DemandesAttestationsIndicator";
import DemandesOuvertures from "./DemandesOuverturesIndicator";
import ExercicesToPayIndicator from "@/components/common/cotisation/ExercicesToPayIndicator";

export default {
  name: "WelcomeHeader",
  created() {
    this.getReceivedMessages({ sender: this.SENT_BY_ADHERANT });
    this.getAdditioanlData();
  },
  components: {
    MyHeader,
    UserEtats,
    ExercicesToPayIndicator,
    WelcomeMessage,
    DemandesAttestations,
    DemandesOuvertures,
  },
  data() {
    return {
      unread_msg_count: 1,
      styleObject: {
        "background-color": "#009688",
        color: "white",
      },
    };
  },
  computed: {
    ...mapGetters({
      unreadMsgCount: "getTotalUnreadMessages",
      getOrganisationWilaysNames: "getOrganisationWilaysNames",
      getRegion: "getRegion",
      organisationLogo: "getOrganisationLogo",
    }),
    ...mapState({ user: (state) => state.adherent.adherent }),
  },
  methods: {
    getAdditioanlData() {
      this.getUser();
      this.$store.cache.dispatch("fetchOrganisation");
      this.$store.cache.dispatch("fetchExercices");
      this.$store.cache.dispatch("fetchOrganisationWilayas");
    },
    ...mapActions({
      getReceivedMessages: "fetchReceivedMessages",
      getUser: "fetchAdherent",
    }),
  },
};
</script>

<style scoped>
.welcome_msg {
  font-size: x-large;
  margin-bottom: 3%;
  margin-top: 3%;
  text-align: center;
  font-style: revert;
  font-weight: 400;
  color: #009688;
}
.my_color {
  color: black;
  font-size: 2.2em;
  margin-top: 10%;
}
.font_size {
  font-size: large;
}
.card_content {
  font-size: 1.2em;
  text-align: center;
}
.user_name {
  font-size: 1.5em;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.mt-5 {
  margin-top: 5%;
}
</style>
