var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.attestationsCount
        ? _c(
            "a-card",
            { attrs: { title: "Demandes attestations" } },
            [
              _c("a-badge", {
                attrs: {
                  slot: "extra",
                  count: _vm.attestationsCount,
                  "number-style": { backgroundColor: "#009688" }
                },
                slot: "extra"
              }),
              _vm._l(_vm.attestations, function(a) {
                return _c(
                  "p",
                  { key: a.id },
                  [
                    _vm._v(
                      "\n      Votre demande d'attestation " +
                        _vm._s(a.type_attestation.nom_fr) +
                        " est\n      "
                    ),
                    _c("etat-attestation-indicator", {
                      attrs: { etat: a.etat }
                    }),
                    _vm._v(".\n    ")
                  ],
                  1
                )
              })
            ],
            2
          )
        : _c("empty-result", {
            attrs: { message: "Vous n'avez aucune demande d'attestation !" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }